@import './../../Utils/Stylesheets/Constants.scss';
@import './../../Utils/Stylesheets/Extends.scss';

.cookie-questions {
  &__title-question {
    @extend .bold-font;

    padding: 0 20px 24px 20px;
    font-size: $font-size-28;
    line-height: 36px;
  }

  &:not(:first-child) {
    padding: 36px 0 0 0;
  }

  &__answer-question {
    margin: 0 20px;
    padding: 0 0 24px 0;
    color: $color-black-4a4f54;
    font-size: $font-size-16;
    line-height: 24px;

    &--border-bottom {
      border-bottom: 1px solid $color-grey-e3e8ed;
    }
  }

  &:last-child .cookie-questions__answer-question {
    padding: 0;
  }

  &__links {
    color: $color-green-bfd732;
    text-decoration: none;

    &:hover,
    &:visited {
      color: $color-green-bfd732;
    }
  }
}

@media (min-width: $screen-size-medium-big-768) {
  .cookie-questions {
    display: flex;
    justify-content: center;

    &__title-question {
      display: inline-block;
      width: 224px;
      padding: 0 16px 0 0;
      margin: 0 0 0 32px;
      letter-spacing: -0.1px;

      &--border-bottom {
        border-bottom: 1px solid $color-grey-e3e8ed;
      }
    }

    &:not(:first-child) {
      padding: 40px 0 0 0;
    }

    &__answer-question {
      width: 464px;
      margin: 0;
      margin: 0 32px 0 0;
      letter-spacing: 0.01px;
    }
  }
}

@media (min-width: $screen-size-big-1024) {
  .cookie-questions {
    &__title-question {
      width: 359px;
      margin: 0 0 0 20px;
      letter-spacing: -0.2px;
    }

    &__answer-question {
      width: 609px;
      margin: 0 20px 0 0;
    }
  }
}

@media (min-width: $screen-size-very-big-1440) {
  .cookie-questions {
    &__title-question {
      width: 336px;
      margin: 0 0 0 152px;
      font-size: $font-size-32;
      line-height: 40px;
      padding: 0 24px 0;
    }

    &:not(:first-child) {
      padding: 80px 0 0 0;
    }

    &__answer-question {
      width: 728px;
      margin: 0 152px 0 0;
      padding: 0 24px 80px 0;
      color: $color-black-3e4c59;

      &--padding-bottom {
        padding: 0 24px 24px 0;
      }
    }

    &:last-child .cookie-questions__answer-question {
      padding: 0 24px 0 0;
    }
  }
}
