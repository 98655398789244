@import './../../Utils/Stylesheets/Constants.scss';
@import './../../Utils/Stylesheets/Extends.scss';

.pp-how-it-works-custom-card {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  
  &__img {
    width: 106px;
    height: 106px;
    margin: 0;
    padding: 0;
    padding-bottom: 30px;

  }

  &__right {
    width: calc(100% - 124px);
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  &__title {
    @extend .bold-font;

    font-size: $font-size-24;
    line-height: 30px;
    margin: 0;
    padding: 0 0 4px 0;
    letter-spacing: -0.25px;
    display: block;
    color: $color-black-000000;
  }

  &__caption {
    font-size: $font-size-16;
    line-height: $font-size-24;
    margin: 0;
    padding: 0;
    color: $color-black-3e4c59;
  }
}

@media (min-width: $screen-size-medium-small-412) {
  .pp-how-it-works-custom-card {
    &__img {
      width: 139px;
      height: 139px;
    }

    &__right {
      width: calc(100% - 139px);
    }
  }
}

@media (min-width: $screen-size-medium-414) {
  .pp-how-it-works-custom-card {
    &__img {
      width: 140px;
      height: 140px;
    }

    &__right {
      width: calc(100% - 140px);
    }
  }
}

@media (min-width: $screen-size-medium-big-768) {
  .pp-how-it-works-custom-card {
    &__img {
      width: 100px;
      height: 100px;
    }

    &__right {
      width: 344px;
    }

    &__title {
      padding: 0 0 16px 0;
    }
  }
}

@media (min-width: $screen-size-big-1024) {
  .pp-how-it-works-custom-card {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0 8px 0 8px;
    text-align: center;

    &__img {
      width: 106px;
      height: 106px;
      margin: 0;
      padding: 0;
    }

    &__right {
      width: 218px;
      margin: 0;
      padding: 36px 0 0 0;
    }

    &__title {
      padding: 0 0 36px 0;
      letter-spacing: -1px;
      font-size: $font-size-20;
      line-height: $font-size-32;

    }
  }
}

@media (min-width: $screen-size-very-big-1440) {
  .pp-how-it-works-custom-card {
    padding: 0;
    width: 255px;

    &__right {
      width: 325px;
    }

    &__title {
      padding: 0 0 36px 0;
      font-size: $font-size-24;
    }
  }
}
