@import './../../Utils/Stylesheets/Constants.scss';
@import './../../Utils/Stylesheets/Extends.scss';

.cookie-types-duration {
  padding: 40px 0;

  &__title {
    @extend .bold-font;

    padding: 0 0 16px 0;
    font-size: $font-size-28;
    line-height: 36px;
  }

  &__text-block {
    color: $color-black-3e4c59;
    font-size: $font-size-16;
    line-height: 24px;
  }

  &__mode-list-block {
    &--first-paragraph {
      padding: 0 0 24px 0;
    }
  }

  &__text {
    padding: 0;

    &--padding-bottom {
      padding: 0;
    }
  }

  &__subtitle {
    @extend .bold-font;

    padding: 16px 0;
    color: $color-grey-7b8794;
    font-size: $font-size-24;
    line-height: 32px;

    &:last-of-type {
      padding: 36px 0 16px;
    }
  }

  &__subsection-title {
    @extend .bold-font;

    padding: 0 0 16px 0;
    color: $color-black-25272a;
    font-size: $font-size-20;
    line-height: 28px;
  }
}

@media (min-width: $screen-size-medium-big-768) {
  .cookie-types-duration {
    display: flex;
    justify-content: center;
    padding: 60px 0;

    &__title {
      width: 224px;
      padding: 0 16px 0 0;
    }

    &__text-block,
    &__subtitle {
      width: 464px;
    }
  }
}

@media (min-width: $screen-size-big-1024) {
  .cookie-types-duration {
    padding: 84px 0;

    &__title {
      width: 303px;
      padding: 0 72px 0 0;
    }

    &__text-block,
    &__subtitle {
      width: 609px;
    }

    &__subtitle {
      padding: 36px 0 16px;
    }
  }
}

@media (min-width: $screen-size-very-big-1440) {
  .cookie-types-duration {
    padding: 80px 0 81px;

    &__title {
      width: 336px;
      padding: 0 24px 0;
      font-size: $font-size-32;
      line-height: 40px;
    }

    &__text-block {
      width: 728px;
      padding: 0 24px 0 0;
    }

    &__mode-list-block {
      &--first-paragraph {
        padding: 0 0 32px 0;
      }
    }

    &__text {
      &--padding-top {
        padding: 24px 0;
      }
    }

    &__subtitle {
      padding: 56px 0 24px;
      font-size: $font-size-20;
      line-height: 28px;

      &:last-of-type {
        padding: 24px 0;
      }
    }

    &__subsection-title {
      padding: 0 0 12px 0;
    }
  }
}
