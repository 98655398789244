@import './Constants.scss';

.bold-font {
  font-weight: 700;
  font-family: $main-font-family-bold;
}

.semibold-font {
  font-weight: 600;
  font-family: $main-font-family-semibold;
}

.medium-font {
  font-weight: 500;
  font-family: $main-font-family-medium;
}

.regular-font {
  font-weight: 400;
  font-family: $main-font-family-regular;
}
