@import './../../Utils/Stylesheets/Constants.scss';
@import './../../Utils/Stylesheets/Extends.scss';

.pp-hero-banner {
  padding: 0 0 80px 0;

  &__title {
    @extend .bold-font;

    display: block;
    font-size: $font-size-40;
    line-height: 48px;
    padding: 80px 0 24px 0;
  }

  &__subtitle {
    margin: 0;
    font-size: $font-size-20;
    line-height: 28px;
    color: $color-black-3e4c59;
  }

  img {
    display: block;
    width: 335px;
    height: 383.6px;
    padding: 0;
    margin: 24px auto 24px;
  }

  a {
    display: block;
    margin: 0 auto;
  }

  &__left_on_big {
    a {
      display: none;
    }
  }
}

@media (min-width: $screen-size-medium-big-768) {
  .pp-hero-banner {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-start;
    padding: 0 0 60px 0;

    &__title {
      padding: 0 0 24px 0;
    }

    img {
      width: 335px;
      height: 383.6px;
      margin: 0;
      padding: 100px 0 0 0;
    }

    &__left_on_big {
      width: 344px;
      height: 508px;
      padding: 60px 16px 0 0;

      a {
        display: block;
        margin: 32px 0 0 0;
      }
    }

    &__right_on_big {
      width: 344px;
      height: 508px;

      a {
        display: none;
      }
    }
  }
}

@media (min-width: $screen-size-big-1024) {
  .pp-hero-banner {
    padding: 0 20px 0 20px;

    &__title {
      font-size: $font-size-44;
      line-height: 52px;
      padding: 100px 0 24px 0;
    }

    img {
      width: 439px;
      height: 502px;
      padding: 0;
    }

    &__right_on_big {
      width: 439px;
      height: 502px;
      padding: 65px 16px 0 0;
    }

    &__left_on_big {
      width: 484px;
      height: 554px;
    }
  }
}

@media (min-width: $screen-size-very-big-1440) {
  .pp-hero-banner {
    padding: 0 152px 0 152px;

    &__title {
      padding: 0 0 24px 0;
    }

    &__subtitle {
      font-size: $font-size-20;
      line-height: 28px;
    }

    img {
      width: 560px;
      height: 642px;
    }

    &__right_on_big {
      width: 560px;
      height: 642px;
      padding: 0;
    }

    &__left_on_big {
      width: 560px;
      height: 340px;
      padding: 150px 16px 0 0;
    }
  }
}
