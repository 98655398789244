@import './../../Utils/Stylesheets/Constants.scss';
@import './../../Utils/Stylesheets/Extends.scss';

* {
  margin: 0;
}

.pp-smartwatch {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding: 0 0 30px 0;

  &__title {
    @extend .bold-font;

    margin: 0 0 32px 0;
    font-size: $font-size-32;
    line-height: 40px;
  }

  &__description {
    color: $color-black-3e4c59;
    line-height: 32px;
    font-size: $font-size-24;

    &--first-paragraph {
      margin: 0 0 37px 0;
    }

    &--second-paragraph {
      margin: 0 0 20px 0;
    }
  }

  &__custom-button {
    margin: 0 auto;
  }

  &__left_on_big {
    a {
      display: none;
    }
  }

  &__image {
    width: 328px;
    height: 344px;
    margin: 0 0 20px 0;
  }
}

.mp-st-form__form {
  &-field {
    display: flex;
    gap: 16px;
    margin: 16px 0;

    width: 100%;
    &--input,
    &--textarea,
    &--select {
      @extend .regular-font;

      background: transparent;
      width: 100%;
      height: 56px;
      box-sizing: border-box;
      padding: 0 16px;
      border: 1px solid #BBC6D2;
      border-radius: 8px;
      font-size: $font-size-16;
      color: $color-grey-7b8794;

      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      &:focus {
        outline: none;
        border: 1px solid $color-green-a9ca26;
      }
    }

    &--textarea {
      height: 132px;
      padding: 16px;
    }

    &--select {
      &-arrow {
        &::after {
          content: "";
          display: block;
          background-image: url('./../../Assets/Images/arrow.png');
          background-position: 50% 50%;
          background-repeat: no-repeat;
          width: 10px;
          height: 10px;
          position: absolute;
          right: 16px;
          top: calc(50% - 5px);
          pointer-events: none;
        }
      } 
    }

    &--checkbox {
      height: 24px;
      opacity: 0;
      width: 24px;
      position: absolute;
      z-index: 999;
      pointer-events: all;
      cursor: pointer;

      :checked~&-span {
        background-color: $color-green-bfd732;
        background-image: url('./../../Assets/Images/check-white.png');
        background-position: 50% 50%;
        background-repeat: no-repeat;
      }
      &-span {
        border: 2px solid $color-green-bfd732;
        border-radius: 8px;
        display: inline-block;
        height: 24px;
        margin: 0;
        min-width: 24px;
      }
      &-text {
        font-size: $font-size-12;
        line-height: $font-size-16;

      }
    }

    &--disclaimer {
      font-size: $font-size-12;
      line-height: $font-size-16;
      margin: 0 0 16px 0;

      &-link {
        display: initial !important;
        text-decoration: underline !important;
      }
        
    }
  }
}
@media (min-width: $screen-size-small-375) {
  .pp-smartwatch {
    &__image {
      width: 335px;
      height: 344px;
    }
  }
}

@media (min-width: $screen-size-medium-small-412) {
  .pp-smartwatch {
    &__image {
      width: 372px;
    }
  }
}

@media (min-width: $screen-size-medium-414) {
  .pp-smartwatch {
    &__image {
      width: 374px;
    }
  }
}

@media (min-width: $screen-size-medium-big-768) {
  .pp-smartwatch {
    justify-content: center;
    flex-direction: row;
    flex-flow: row-reverse nowrap;
    padding: 60px 0;

    &__left_on_big {
      width: 344px;
      margin: 0 16px 0 0;

      a {
        display: block;
      }
    }

    &__description {
      &--second-paragraph {
        margin: 0 0 32px 0;
      }
    }

    &__custom-button {
      margin: 0;
    }

    &__right_on_big {
      width: 344px;

      a {
        display: none;
      }
    }

    &__image {
      width: 344px;
      margin: 0;
    }
  }
}

@media (min-width: $screen-size-big-1024) {
  .pp-smartwatch {
    padding: 0 20px 0 44px;

    &__left_on_big {
      width: 311px;
      padding: 124.5px 0;
      margin: 0;
    }

    &__custom-button {
      width: 233px;
    }

    &__right_on_big {
      width: 607px;

      &__image {
        object-fit: contain;
      }
    }

    &__image {
      width: 609px;
      height: 609px;
      margin: 0;
    }
  }
}

@media (min-width: $screen-size-very-big-1440) {
  .pp-smartwatch {
    padding: 0 155px 0 180px;

    &__left_on_big {
      width: 464px;
      padding: 30px 0 80px;
    }

    &__custom-button {
      width: 176px;
    }

    &__image {
      width: 607px;
      height: 620px;
      margin-left: 60px;

    }
  }
}
