@import './../../Utils/Stylesheets/Constants.scss';
@import './../../Utils/Stylesheets/Extends.scss';

.pp-why-BeHealthy {
  &__title {
    @extend .bold-font;

    font-size: $font-size-32;
    line-height: $font-size-40;
    padding: 32px 0 24px 0;
    text-align: center;

    span {
      color: $color-green-bfd732;
    }
  }

  &__introduction {
    margin: 0 0 64px 0;
    padding: 0;
    font-size: $font-size-16;
    line-height: $font-size-24;
    color: $color-black-3e4c59;
    text-align: center;

    strong {
      @extend .bold-font;
    }
  }

  &__first-section-container {
    display: block;
    margin: 0;
    padding: 0;
  }

  &__second-section-container {
    display: flex;
    flex-flow: column wrap;
  }

  &__image {
    display: block;
    margin: 0 auto 44px;
  }

  &__list {
    padding: 0;
    list-style: none;

    &--item {
      @extend .medium-font;

      display: flex;
      align-items: center;
      margin: 0 0 32px 0;
      font-size: $font-size-16;
      line-height: $font-size-24;
      color: $color-black-3e4c59;
    }

    &--item-icon {
      display: block;
      width: 42px;
      height: 42px;
      padding: 0 16px 0 0;
    }
  }
}

@media (min-width: $screen-size-medium-big-768) {
  .pp-why-BeHealthy {
    &__title {
      padding: 40px 0 20px 0;
    }

    &__introduction {
      margin: 32px 0 40px 0;
    }
  }
}

@media (min-width: $screen-size-big-1024) {
  .pp-why-BeHealthy {
    padding: 0 20px 0 20px;

    &__title {
      padding: 60px 0 20px 0;
    }

    &__introduction {
      margin: 0 0 60px 0;
    }

    &__second-section-container {
      flex-flow: row-reverse nowrap;
      justify-content: space-between;
      align-items: center;
    }

    &__image {
      margin: 0 auto;
    }
  }
}

@media (min-width: $screen-size-very-big-1440) {
  .pp-why-BeHealthy {
    padding: 0 152px 0 152px;

    &__title {
      padding: 60px 0 24px 0;
    }

    &__introduction {
      margin: 0 0 40px 0;
    }

    &__image {
      width: 560px;
      height: 435px;
    }

    &__list {
      &--item {
        width: 576px;

        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
