@import './../../Utils/Stylesheets/Constants.scss';
@import './../../Utils/Stylesheets/Extends.scss';

.pp-nav-bar-desktop {
  position: fixed;
  top: 0;
  width: 1440px;
  height: 88px;
  padding: 0 calc((100vw - 1440px) / 2) !important;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background: $color-white-ffffff;
  z-index: $in-face-layer;

  &__section-container {
    height: 88px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    img {
      width: 143px;
      height: 40px;
      padding: 0 0 0 152px;
      cursor: pointer;
    }

    a {
      @extend .medium-font;

      font-size: $font-size-16;
      line-height: $font-size-24;
      padding: 32px 16px 32px 16px;
      color: $color-black-3e4c59;
      height: 24px;

      &:hover {
        background: $color-grey-f9fafb;
      }

      &.pp-nav-bar-desktop__section-container--button {
        @extend .semibold-font;

        display: block;
        width: 176px;
        height: 48px;
        margin: 0 152px 0 0 !important;
        padding: 0;
        color: $color-black-25272a;
        font-size: $font-size-18;
        line-height: 48px;
        border-radius: 26px;
        text-align: center;
        background: $color-green-bfd732;
        border: none;

        &:hover {
          color: $color-black-3e4c59;
          background: $color-green-d0e869;
        }
      }
    }
  }
}
