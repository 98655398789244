@import '../../Utils/Stylesheets/Constants.scss';
@import '../../Utils/Stylesheets/Extends.scss';

#DeliveryOptions {
  background: $color-green-fafcee;
}

.pp-delivery-options {
  text-align: center;

  &__title {
    @extend .bold-font;

    padding: 32px 0 24px 0;
    margin: 0;
    font-size: $font-size-32;
    line-height: $font-size-40;
  }

  &__introduction {
    padding: 0 0 36px 0;
    color: $color-black-3e4c59;
    font-size: $font-size-16;
    line-height: $font-size-24;
  }

  &__options-item {
    padding: 0 0 36px 0;

    &-title {
      @extend .bold-font;

      padding: 0 0 16px 0;
      font-size: $font-size-20;
      line-height: $font-size-28;
    }

    &-text {
      padding: 0 0 16px 0;
      border-bottom: 1px solid $color-grey-e3e8ed;
      font-size: $font-size-16;
      line-height: $font-size-24;
    }

    &:last-child {
      padding: 0 0 32px 0;
    }
  }
}

@media (min-width: $screen-size-big-1024) {
  .pp-delivery-options {
    padding: 0 20px 0 20px;

    &__title {
      padding: 60px 0 24px 0;
    }

    &__introduction {
      padding: 0 0 60px 0;
    }

    &__options-block {
      display: flex;
      text-align: start;
      padding: 0 0 60px 0;
    }

    &__options-item {
      width: 333px;
      padding: 0 40px 0 0;

      &-text {
        padding: 0;
        border: none;
      }

      &:last-child {
        padding: 0;
      }
    }
  }
}

@media (min-width: $screen-size-very-big-1440) {
  .pp-delivery-options {
    padding: 0 180.5px 0 180.5px;
  }
}
