/* Colors */
$color-white-ffffff: #ffffff;
$color-grey-f9fafb: #f9fafb;
$color-grey-e3e8ed: #e3e8ed;
$color-grey-fafafa: #fafafa;
$color-grey-f1f3f6: #f1f3f6;
$color-grey-7b8794: #7b8794;
$color-green-bfd732: #bfd732;
$color-green-d0e869: #d0e869;
$color-green-a9ca26: #a9ca26;
$color-green-eaf7c0: #eaf7c0;
$color-green-e4f3a5: #e4f3a5;
$color-green-f3fadb: #f3fadb;
$color-green-fafcee: #fafcee;
$color-black-000000: #000000;
$color-black-25272a: #25272a;
$color-black-25272acc: #25272acc;
$color-black-3e4c59: #3e4c59;
$color-black-4a4f54: #4a4f54;

/* Font Families */
$main-font-family-bold: 'Proxima Nova Bold', sans-serif;
$main-font-family-semibold: 'Proxima Nova Semibold', sans-serif;
$main-font-family-medium: 'Proxima Nova Medium', sans-serif;
$main-font-family-regular: 'Proxima Nova Regular', sans-serif;

/* Font Sizes */
$font-size-56: 56px;
$font-size-52: 52px;
$font-size-48: 48px;
$font-size-44: 44px;
$font-size-40: 40px;
$font-size-36: 36px;
$font-size-32: 32px;
$font-size-28: 28px;
$font-size-24: 24px;
$font-size-20: 20px;
$font-size-18: 18px;
$font-size-16: 16px;
$font-size-12: 12px;
$font-size-8: 8px;

/* Screen Sizes */
$screen-size-very-big-1440: 1440px;
$screen-size-big-1024: 1024px;
$screen-size-medium-big-768: 768px;
$screen-size-medium-414: 414px;
$screen-size-medium-small-412: 412px;
$screen-size-small-375: 375px;
$screen-size-very-small-360: 360px;

/* Layers Indexes */
$back-layer: 0;
$base-layer: 5;
$front-layer: 10;
$main-front-layer: 15;
$in-face-layer: 20;
