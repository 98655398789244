@import './../../Utils/Stylesheets/Constants.scss';
@import './../../Utils/Stylesheets/Extends.scss';

.pp-cookie-list {
  padding: 40px 0 60px;

  .pp-cookie-table {
    &:first-of-type {
      margin: 0 0 1px 0;
    }

    &--spacing-bottom {
      margin: 0 0 1px 0;
    }
  }

  &__main-title {
    @extend .bold-font;

    width: 335px;
    padding: 0 0 48px 0;
    font-size: $font-size-32;
    line-height: 40px;
  }
}

@media (min-width: $screen-size-medium-small-412) {
  .pp-cookie-list {
    &__main-title {
      width: 372px;
    }
  }
}

@media (min-width: $screen-size-medium-414) {
  .pp-cookie-list {
    &__main-title {
      width: 374px;
      padding: 0 0 52px 0;
    }
  }
}

@media (min-width: $screen-size-medium-big-768) {
  .pp-cookie-list {
    padding: 60px 0;

    &__main-title {
      width: 704px;
      padding: 0 0 40px 0;
    }
  }
}

@media (min-width: $screen-size-big-1024) {
  .pp-cookie-list {
    padding: 60px 0 109px;

    .pp-cookie-table {
      &--spacing-bottom {
        margin: 0 0 2px 0;
        height: 174px;
      }
    }

    &__main-title {
      width: 233px;
      padding: 0 0 56px 0;
    }
  }
}

@media (min-width: $screen-size-very-big-1440) {
  .pp-cookie-list {
    padding: 76px 0 233px;

    .pp-cookie-table {
      &--spacing-bottom {
        margin: 0 0 3px 0;
        height: 174px;
      }
    }

    &__main-title {
      padding: 0 0 44px 33px;
    }
  }
}
