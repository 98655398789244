@import './../../Utils/Stylesheets/Constants.scss';
@import './../../Utils/Stylesheets/Extends.scss';

.pp-footer {
  display: block;
  margin: 0;
  padding: 0;
  background: $color-black-25272a;
  color: $color-white-ffffff;
  height: 457px !important;
  box-sizing: border-box;

  &__top {
    display: block;
    margin: 0;
    padding: 0;
  }

  &__bottom {
    display: block;
    margin: 0;
    padding: 0;
  }

  &__logo {
    display: block;
    margin: 0;
    padding: 48px 0 40px 0;
    width: 150px;
    height: 44px;
  }

  &__legal-links {
    display: block;
    margin: 0;
    padding: 0 0 40px 0;
    border-bottom: 1px solid $color-grey-e3e8ed;

    &--title {
      @extend .bold-font;

      font-size: $font-size-16;
      line-height: $font-size-24;
      display: block;
      margin: 0;
      padding: 0;
    }

    &--list {
      display: block;
      margin: 0;
      padding: 0;

      &-item {
        font-size: $font-size-16;
        line-height: $font-size-24;
        display: block;
        margin: 0;
        padding: 12px 0 0 0;
        cursor: pointer;

        a {
          color: $color-white-ffffff;
          text-decoration: none;
          text-decoration-color: $color-white-ffffff;

          &:link,
          &:active,
          &:any-link,
          &:-webkit-any-link {
            color: $color-white-ffffff;
            text-decoration: none;
            text-decoration-color: $color-white-ffffff;
          }
        }
      }
    }
  }

  &__social-media-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 22px 0 22px 0;

    .pp-custom-icon {
      margin: 0 8px 0 0;
      padding: 0;
      width: 40px;
      height: 40px;

      &:last-child {
        margin: 0;
      }
    }
  }

  &__copyright {
    text-align: center;
    margin: 0;
    padding: 0 0 48px 0;
    font-size: $font-size-12;
    line-height: $font-size-20;
    color: $color-grey-f9fafb;
  }
}

@media (min-width: $screen-size-medium-big-768) {
  .pp-footer {
    padding: 48px 0 48px 0;
    height: 373px !important;

    &__top {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0 60px 40px 0;
      border-bottom: 1px solid $color-grey-e3e8ed;
      width: auto;
      margin: 0 auto;
      height: 132px;
    }

    &__logo {
      margin: 0;
      padding: 0;
      width: 150px;
      height: 44px;
    }

    &__legal-links {
      margin: 0;
      padding: 0;
      width: 176px;
      border: none;
    }

    &__social-media-container {
      .pp-custom-icon {
        margin: 0 40px 0 0;

        &:last-child {
          margin: 0;
        }
      }
    }

    &__copyright {
      padding: 0;
    }
  }
}

@media (min-width: $screen-size-big-1024) {
  .pp-footer {
    padding: 48px 20px 48px 20px;
    height: 328px !important;

    &__top {
      padding: 0 0 60px 0;
    }

    &__bottom {
      display: flex;
      flex-flow: row-reverse nowrap;
      align-items: flex-end;
      justify-content: space-between;
      height: 60px;
    }

    &__legal-links {
      width: 234px;
      padding: 0;
    }

    &__social-media-container {
      align-items: flex-end;
      justify-content: center;
      height: 60px;
      padding: 0;
    }

    &__social-media-container {
      .pp-custom-icon {
        margin: 0 8px 0 0;

        &:last-child {
          margin: 0;
        }
      }
    }

    &__copyright {
      font-size: $font-size-16;
      line-height: $font-size-24;
    }
  }
}

@media (min-width: $screen-size-very-big-1440) {
  .pp-footer {
    padding: 48px 152px 48px 152px;
    height: 328px !important;

    &__legal-links {
      width: 176px;
    }
  }
}
