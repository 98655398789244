@import './../../Utils/Stylesheets/Constants.scss';
@import './../../Utils/Stylesheets/Extends.scss';

.pp-cookie-intro {
  padding: 48px 0 28px 0;
  text-align: center;

  &__title {
    @extend .bold-font;

    padding: 0;
    margin: 0 0 16px 0;
    font-size: $font-size-32;
    line-height: 40px;
  }

  &__intro {
    color: $color-black-3e4c59;
    font-size: $font-size-20;
    line-height: 32px;
  }
}

@media (min-width: $screen-size-big-1024) {
  .pp-cookie-intro {
    padding: 68px 0 0 0;
  }
}

@media (min-width: $screen-size-very-big-1440) {
  .pp-cookie-intro {
    padding: 100px 0 80px 0;

    &__title {
      margin: 0 0 32px 0;
      font-size: $font-size-40;
      line-height: 48px;
    }

    &__intro {
      font-size: $font-size-16;
      line-height: 24px;
    }
  }
}
