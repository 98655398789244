@import './../../Utils/Stylesheets/Constants.scss';
@import './../../Utils/Stylesheets/Extends.scss';

.pp-app {
  .mp-thankyoupage {
    overflow: hidden;
  }
  
  a {
    text-decoration: none;
  }
}
.mp-thankyoupage__wrapper {
  &--content {
    display: flex;
    align-items: center;
    gap: 40px;
    flex-direction: column;
    padding: 60px 0;

    &-text {
      &--title {
        @extend .bold-font;

        font-size: 40px;
        line-height: 48px;
      }
      &--description {
        @extend .regular-font;

        font-size: 16px;
        line-height: 24px;
      }
    }

  }
}

@media (min-width: $screen-size-big-1024) {
  .mp-thankyoupage__wrapper {
    padding: 0 calc(50vw - 512px)!important;
    &--content {
      padding: 84px 20px 84px 44px;
      flex-direction: row;
    }
  }
}

@media (min-width: $screen-size-very-big-1440) {
  .mp-thankyoupage__wrapper {
    padding: 0 calc(50vw - 720px)!important;
    &--content {
      padding: 84px 152px 84px 180px;
    }
  }
}
