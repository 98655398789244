@import './../../Utils/Stylesheets/Constants.scss';
@import './../../Utils/Stylesheets/Extends.scss';

.pp-faqs-intro {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 58px 20px 60px;

  &__title {
    @extend .bold-font;

    padding: 0 0 16px 0;
    font-size: $font-size-32;
    line-height: $font-size-40;
  }

  &__text {
    padding: 0 31px 36px;
    color: $color-black-3e4c59;
    font-size: $font-size-20;
    line-height: $font-size-32;
  }

  &__links-container {
    &--left-on-big {
      margin: 0 0 12px 0;
    }

    a {
      @extend .bold-font;

      display: flex;
      justify-content: center;
      align-items: center;
      width: 191px;
      height: 48px;
      margin: 0 0 12px 0;
      border: 1px solid $color-green-d0e869;
      border-radius: 8px;
      background: $color-green-f3fadb;
      color: $color-green-a9ca26;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        border-color: $color-green-bfd732;
      }

      &:active {
        background: $color-green-e4f3a5;
        border: 1px solid $color-green-eaf7c0;
        color: $color-black-3e4c59;
      }
    }
  }
}

@media (min-width: $screen-size-small-375) {
  .pp-faqs-intro {
    &__text {
      padding: 0 0 36px;
    }

    &__links-container {
      a {
        width: 194px;
      }
    }
  }
}

@media (min-width: $screen-size-medium-small-412) {
  .pp-faqs-intro {
    padding: 72px 20px 84px;

    &__links-container {
      a {
        width: 215px;
      }
    }
  }
}

@media (min-width: $screen-size-medium-big-768) {
  .pp-faqs-intro {
    padding: 60px 32px;

    &__text {
      padding: 0 50px 32px;
    }

    &__links-container {
      display: flex;

      &--left-on-big {
        margin: 0 16px 0 0;
      }

      a {
        width: 224px;
        margin: 0 0 16px 0;
      }
    }
  }
}

@media (min-width: $screen-size-big-1024) {
  .pp-faqs-intro {
    padding: 100px 0 60px;

    &__text-container {
      padding: 0 144px;
    }

    &__title {
      padding: 0 120px 16px;
      font-size: $font-size-36;
    }

    &__text {
      padding: 0 50px 44px;
    }

    &__links-container {
      &--left-on-big {
        display: flex;
        margin: 0 16px 0 21px;
      }

      &--right-on-big {
        display: flex;
        margin: 0 21px 0 0;
      }

      a {
        width: 233.5px;
        margin: 0 16px 0 0;

        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

@media (min-width: $screen-size-very-big-1440) {
  .pp-faqs-intro {
    padding: 100px 0 56px;

    &__text-container {
      padding: 0 344px;
    }

    &__title {
      padding: 0 140px 16px;
    }

    &__text {
      padding: 0 60px 44px;
    }

    &__links-container {
      &--left-on-big {
        margin: 0 16px 0 344px;
      }

      &--right-on-big {
        margin: 0 344px 0 0;
      }

      a {
        width: 176px;
        height: 40px;
      }
    }
  }
}
