@import './../../Utils/Stylesheets/Constants.scss';
@import './../../Utils/Stylesheets/Extends.scss';

.pp-stats {
  padding: 32px 0 0;
  text-align: center;

  &__title {
    @extend .bold-font;

    padding: 0 0 24px 0;
    font-size: $font-size-32;
    line-height: 40px;
  }

  &__text {
    padding: 0 0 32px 0;
    color: $color-black-3e4c59;
    font-size: $font-size-16;
    line-height: 24px;
  }

  &__bottom-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 32px 0 0 0;

    &-section {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 152px;
      height: 208px;
      margin: 0 0 32px 0;
    }

    &-img {
      width: 92px;
      height: 92px;
    }

    &-value {
      @extend .bold-font;

      padding: 24px 0 8px;
      font-size: $font-size-40;
      line-height: 24px;
    }

    &-paragraph {
      font-size: $font-size-18;
      line-height: 20px;
      letter-spacing: 0.2px;
    }
  }
}

@media (min-width: $screen-size-big-1024) {
  .pp-stats {
    padding: 60px 0 0;

    &__text {
      padding: 0 0 60px 0;
    }

    &__bottom-block {
      justify-content: center;
      flex-direction: row;
      padding: 40px 0;

      &-section {
        height: 212px;
        margin: 0 80px 0 0;

        &:last-of-type {
          margin: 0;
        }
      }
    }
  }
}

@media (min-width: $screen-size-very-big-1440) {
  .pp-stats {
    &__bottom-block {
      &-img {
        width: 96px;
        height: 96px;
      }
    }
  }
}
