@import './../../Utils/Stylesheets/Constants.scss';
@import './../../Utils/Stylesheets/Extends.scss';

.pp-vertical {
  &__title {
    @extend .bold-font;

    display: block;
    padding: 60px 0 24px 0;
    font-size: $font-size-32;
    line-height: $font-size-40;
  }

  &__paragraph {
    display: block;
    margin: 0;
    padding: 0;
    font-size: $font-size-16;
    line-height: $font-size-24;
    color: $color-black-3e4c59;
  }

  &__list {
    display: block;
    margin: 0;
    padding: 0 0 88px 0;
    list-style: none;

    &--item {
      width: 100%;
      margin: 28px 0 0 0;
      padding: 0;
      color: $color-black-3e4c59;
      font-size: $font-size-16;
      line-height: $font-size-24;
      list-style-position: inside;

      &-top {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        & p {
          font-size: 20px;
          font-weight: 500;
          line-height: 32px;
        }
      }

      img {
        width: 24px;
        height: 24px;
        margin: 0 16px 0 0;
      }

      &:first-child {
        margin: 0;
      }

      &-bottom {
        margin: 8px 0 0 40px;
      }
    }
  }

  &__side-by-side-container--half:first-child {
    display: block;
    margin: 0 0 36px 0;
  }
}

@media (min-width: $screen-size-medium-big-768) {
  .pp-vertical {
    margin: 0;
    padding: 60px 0 60px 0;

    &__title {
      margin: 0 0 36px 0;
      padding: 0;
    }

    &__list {
      margin: 0;
      padding: 0;

      &--item {
        &:first-of-type {
          margin: 0;
        }
      }
    }

    &__side-by-side-container {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: flex-start;
      margin: 0;
      padding: 0;

      &--half {
        width: calc(50% - 8px);
        margin: 0;
        padding: 0;

        &:first-child {
          margin: 0;
          padding: 0 16px 0 0;
        }
      }
    }
  }
}

@media (min-width: $screen-size-big-1024) {
  .pp-vertical {
    margin: 0;
    padding: 84px 20px 84px 44px;

    &__list {
      &--item {
        span {
          width: 430px;
        }
      }
    }

    &__side-by-side-container {
      &--half {
        &:first-child {
          width: 436px;
          padding: 0 60px 0 0;
        }

        &:last-child {
          width: 464px;
        }
      }
    }
  }
}

@media (min-width: $screen-size-very-big-1440) {
  .pp-vertical {
    margin: 0;
    padding: 60px 152px 64px 152px;

    &__title {
      margin: 0 0 24px 0;
      padding: 0;
    }

    &__side-by-side-container {
      &--half {
        &:first-child {
          width: 506px;
          padding: 0 138px 0 0;
        }

        &:last-child {
          width: 492px;
        }
      }
    }
  }
}
