@import './../../Utils/Stylesheets/Constants.scss';
@import './../../Utils/Stylesheets/Extends.scss';

.pp-testimonials-custom-card {
  position: relative;
  padding: 104px 24px 24px 24px;
  margin: 0;
  background: $color-grey-f9fafb;
  border-radius: 12px;
  text-align: left;

  &::before {
    content: '';
    position: absolute;
    background-image: url('./../../Assets/Images/left-quote.svg');
    background-repeat: no-repeat;
    height: 52px;
    width: 64px;
    top: 38px;
    left: 24px;
    z-index: $base-layer;
  }

  &__caption {
    font-size: $font-size-16;
    line-height: $font-size-24;
    color: $color-black-3e4c59;
    margin: 0 0 12px 0;
  }

  &__author {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    img {
      width: 80px !important;
      height: 80px !important;
    }

    &--wrap {
      margin: 0;
      padding: 0;
    }

    &--title {
      @extend .regular-font;

      font-size: $font-size-16;
      line-height: $font-size-24;
      color: $color-green-a9ca26;
      font-weight: 600;
    }

    &--description {
      @extend .regular-font;

      font-size: $font-size-16;
      line-height: $font-size-20;
      margin: 2px 0 0 0;
      font-weight: 600;
    }
  }
}

@media (min-width: $screen-size-medium-big-768) {
  .pp-testimonials-custom-card {
    width: 335px;
    height: 364px;
    box-sizing: border-box;
  }
}

@media (min-width: $screen-size-big-1024) {
  .pp-testimonials-custom-card {
    width: 307px;
    height: 388px;
  }
}

@media (min-width: $screen-size-very-big-1440) {
  .pp-testimonials-custom-card {
    width: 368px;
    height: 320px;

    &__caption {
      margin: 0 0 24px 0;
    }
  }
}
