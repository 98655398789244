@import './../../Utils/Stylesheets/Constants.scss';
@import './../../Utils/Stylesheets/Extends.scss';

.pp-custom-button {
  &__btn {
    @extend .semibold-font;

    width: 335px;
    height: 52px;
    line-height: $font-size-48;
    display: block;
    box-sizing: border-box;
    border-radius: 26px;
    font-size: $font-size-18;
    transition: all 0.5s linear;
    color: $color-green-a9ca26;
    background: $color-white-ffffff;
    border: 1px solid $color-green-bfd732;
    text-align: center;
    cursor: pointer;

    &:hover {
      color: $color-green-bfd732;
      border: 1px solid $color-green-d0e869;
    }

    &--filled {
      color: $color-black-25272a;
      background: $color-green-bfd732;
      border: 1px solid $color-green-bfd732;

      &:hover {
        color: $color-black-3e4c59;
        background: $color-green-d0e869;
        border: 1px solid $color-green-d0e869;
      }
    }
  }
}
