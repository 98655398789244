@import './Utils/Stylesheets/Constants.scss';
@import './Utils/Stylesheets/Extends.scss';

@font-face {
  font-family: 'Proxima Nova Regular';
  font-style: normal;
  font-weight: 400;
  src: url('/Assets/Fonts/ProximaNova-Regular.woff') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Medium';
  font-style: normal;
  font-weight: 500;
  src: url('/Assets/Fonts/ProximaNova-Medium.woff') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Semibold';
  font-style: normal;
  font-weight: 600;
  src: url('/Assets/Fonts/ProximaNova-Semibold.woff') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Bold';
  font-style: normal;
  font-weight: 700;
  src: url('/Assets/Fonts/ProximaNova-Bold.woff') format('truetype');
  font-display: swap;
}

body {
  padding: 0;
  margin: 0;
  background: $color-white-ffffff;
  color: $color-black-25272a;
}

.pp-app {
  @extend .regular-font;

  display: block;
  width: 100%;
  background: $color-white-ffffff;
  color: $color-black-25272a;
  z-index: $base-layer;
  overflow-x: hidden;
  padding: 88px 0 0 0;

  b {
    @extend .bold-font;
  }

  .CookieConsent {
    display: block !important;
    margin: 0 !important;
    padding: 0 20px 0 20px !important;
    width: 100% !important;
    height: 332px !important;
    box-sizing: border-box !important;
    background: $color-black-25272acc !important;
    flex: none !important;

    div {
      display: block !important;
      margin: 0 !important;
      padding: 0 !important;
      flex: none !important;

      &:first-child {
        margin: 80px 0 24px 0 !important;
        padding: 0 !important;
        color: $color-white-ffffff !important;
        font-size: $font-size-16 !important;
        line-height: $font-size-24 !important;
        flex: none !important;

        a {
          margin: 0 !important;
          padding: 0 !important;
          color: $color-green-a9ca26 !important;
          text-decoration: none !important;
          font-size: $font-size-16 !important;
          line-height: $font-size-24 !important;
          flex: none !important;
        }
      }

      &:last-child {
        margin: 0 !important;
        padding: 0 !important;
        flex: none !important;

        button {
          @extend .semibold-font;

          display: block !important;
          margin: 0 auto 80px auto !important;
          padding: 0 !important;
          width: 335px !important;
          height: 52px !important;
          background: $color-green-bfd732 !important;
          border-radius: 26px !important;
          font-size: $font-size-18 !important;
          line-height: $font-size-24 !important;
          flex: none !important;

          &:hover {
            color: $color-black-3e4c59 !important;
            background: $color-green-d0e869 !important;
            border: 1px solid $color-green-d0e869 !important;
          }
        }
      }
    }
  }
}

@media (min-width: $screen-size-medium-small-412) {
  .pp-app {
    .CookieConsent {
      height: 304px !important;

      div {
        &:last-child {
          button {
            width: 335px !important;
          }
        }
      }
    }
  }
}

@media (min-width: $screen-size-medium-414) {
  .pp-app {
    .CookieConsent {
      div {
        &:last-child {
          button {
            width: 335px !important;
          }
        }
      }
    }
  }
}

@media (min-width: $screen-size-medium-big-768) {
  .pp-app {
    .CookieConsent {
      height: 236px !important;
      padding: 0 32px 0 32px !important;

      div {
        &:first-child {
          margin: 40px 0 60px 0 !important;
        }

        &:last-child {
          button {
            width: 335px !important;
            margin: 0 auto 40px auto !important;
          }
        }
      }
    }
  }
}

@media (min-width: $screen-size-big-1024) {
  .pp-app {
    .CookieConsent {
      display: flex !important;
      flex-flow: row nowrap !important;
      justify-content: space-between !important;
      align-items: center !important;
      padding: 40px calc(20px + ((100vw - 1024px) / 2)) 40px
        calc(20px + ((100vw - 1024px) / 2)) !important;
      height: 128px !important;

      div {
        margin: 0 !important;
        padding: 0 !important;

        &:first-child {
          width: 608px !important;
          height: 48px !important;
          margin: 0 !important;
          padding: 0 !important;
        }

        &:last-child {
          margin: 0 !important;
          padding: 0 !important;

          button {
            width: 335px !important;
            height: 48px !important;
            margin: 0 !important;
            padding: 0 !important;
          }
        }
      }
    }
  }
}

@media (min-width: $screen-size-very-big-1440) {
  .pp-app {
    .CookieConsent {
      padding: 42px calc(88.5px + ((100vw - 1440px) / 2)) 42px
        calc(151.5px + ((100vw - 1440px) / 2)) !important;

      div {
        &:first-child {
          width: 751px !important;
        }

        &:last-child {
          button {
            height: 52px !important;
          }
        }
      }
    }
  }
}
