@import './../../Utils/Stylesheets/Constants.scss';
@import './../../Utils/Stylesheets/Extends.scss';

.pp-nav-bar-mobile {
  position: fixed;
  top: 0;
  width: 100%;
  height: 88px;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 20px rgba(0, 62, 107, 0.16);
  background: $color-white-ffffff;
  z-index: $front-layer;

  &__logo {
    width: 143px;
    height: 40px;
    padding: 0 0 0 30px;
    cursor: pointer;
  }
}

.pp-nav-bar-mobile-list {
  position: fixed;
  top: 0;
  right: 0;
  width: 285px;
  height: 100vh;
  z-index: $main-front-layer;
  background: $color-black-25272a;
  padding: 76px 16px 0 16px;
  margin: 0;
  box-sizing: border-box;
  opacity: 1;
  transition: all 0.5s linear;

  a {
    @extend .medium-font;

    border-top: 1px solid $color-green-bfd732;
    display: block;
    width: 100%;
    font-size: $font-size-16;
    padding: 16px 0 16px 19px;
    box-sizing: border-box;
    color: $color-grey-f1f3f6;

    &:first-of-type {
      border-top: none;
    }

    &.pp-nav-bar-mobile-list__enroll-button {
      @extend .semibold-font;

      position: absolute;
      display: inline-block;
      width: calc(100% - 34px);
      height: 48px;
      margin: 0;
      padding: 0;
      color: $color-black-25272a;
      font-size: $font-size-18;
      border-radius: 26px;
      text-align: center;
      background: $color-green-bfd732;
      border: none;
      bottom: 44px;
      left: 17px;

      &:hover {
        color: $color-black-3e4c59;
        background: $color-green-d0e869;
      }
    }
  }

  &--closed {
    opacity: 0;
    display: none;
  }
}

@media (min-width: $screen-size-medium-small-412) {
  .pp-nav-bar-mobile-list {
    width: 314px;
  }
}

@media (min-width: $screen-size-medium-414) {
  .pp-nav-bar-mobile-list {
    width: 316px;
  }
}

@media (min-width: $screen-size-medium-big-768) {
  .pp-nav-bar-mobile {
    &__logo {
      padding: 0 0 0 32px;
    }

    &__button {
      padding: 0 74px 0 0;

      &--open {
        padding: 0;
      }
    }
  }

  .pp-nav-bar-mobile-list {
    width: 376px;
  }
}

@media (min-width: $screen-size-big-1024) {
  .pp-nav-bar-mobile {
    &__logo {
      padding: 0 0 0 64px;
    }

    &__button {
      padding: 0 66px 0 0;

      &--open {
        padding: 0;
      }
    }
  }

  .pp-nav-bar-mobile-list {
    width: 504px;
  }
}

@media (min-width: $screen-size-very-big-1440) {
  .pp-nav-bar-mobile {
    display: none;
  }

  .pp-nav-bar-mobile-list {
    display: none;
  }
}
