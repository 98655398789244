@import './../../Utils/Stylesheets/Constants.scss';
@import './../../Utils/Stylesheets/Extends.scss';

.pp-testimonials {
  padding: 60px 0 38px 0;
  text-align: center;

  &__title {
    @extend .bold-font;

    font-size: $font-size-32;
    line-height: $font-size-40;
  }

  &__subtitle {
    font-size: $font-size-24;
    line-height: $font-size-32;
    padding: 16px 0 28px 0;
  }

  &__workflow {
    padding: 24px 0 24px 0;
    margin: 0;
  }
}

@media (min-width: $screen-size-small-375) {
  .pp-testimonials {
    padding: 48px 0 38px 0;
  }
}

@media (min-width: $screen-size-medium-big-768) {
  .pp-testimonials {
    &__workflow {
      padding: 0 0 24px 0;
      margin: 0;
    }
  }
}

@media (min-width: $screen-size-big-1024) {
  .pp-testimonials {
    padding: 68px 24px 82px 24px;

    &__title {
      font-size: $font-size-32;
      line-height: $font-size-40;
    }
  
    &__subtitle {
      padding: 16px 0 42px 0;
    }

    &__workflow {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin: 0;

      .pp-testimonials-custom-card:nth-child(2) {
        margin: 0 28px 0 28px;
      }
    }
  }
}

@media (min-width: $screen-size-very-big-1440) {
  .pp-testimonials {
    &__workflow {
      .pp-testimonials-custom-card:nth-child(2) {
        margin: 0 16px 0 16px;
      }
    }
  }
}
