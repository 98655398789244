@import './../../Utils/Stylesheets/Constants.scss';
@import './../../Utils/Stylesheets/Extends.scss';

.pp-terms-and-conditions {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;

  &__main-section {
    padding: 48px 20px 0;
    color: $color-black-4a4f54;

    &-title {
      @extend .bold-font;

      padding: 0 0 24px 0;
      color: $color-black-25272a;
      font-size: $font-size-32;
      line-height: $font-size-40;
      text-align: center;
    }

    &-subtitle {
      padding: 0 0 24px 0;
      font-size: $font-size-28;
      line-height: $font-size-36;
      text-transform: capitalize;
    }

    &-paragraph {
      padding: 0 0 24px 0;
      font-size: $font-size-16;
      line-height: $font-size-24;
      text-align: justify;

      &--link {
        color: $color-green-bfd732;
        text-decoration: none;

        &:hover,
        &:active,
        &:visited {
          color: $color-green-bfd732;
        }
      }

      &:nth-child(5) {
        padding: 0 0 60px 0;
      }
    }

    &-second-block {
      &:last-of-type {
        padding: 0 0 36px 0;
      }
    }
  }

  &__footer-section {
    padding: 0 20px;
    background-image: linear-gradient(
        to bottom,
        $color-black-25272a 0%,
        $color-black-25272a 100%
      ),
      linear-gradient(
        to bottom,
        $color-black-25272a 0%,
        $color-black-25272a 100%
      );
    background-color: $color-black-25272a;
    background-clip: content-box, padding-box;
  }
}

@media (min-width: $screen-size-medium-big-768) {
  .pp-terms-and-conditions {
    &__main-section {
      padding: 60px 32px 0;
    }

    &__footer-section {
      padding: 0 32px;
    }
  }
}

@media (min-width: $screen-size-big-1024) {
  .pp-terms-and-conditions {
    &__main-section {
      padding: 80px 52px 0;

      &-second-block {
        &:last-of-type {
          padding: 0 0 80px 0;
        }
      }
    }

    &__footer-section {
      padding: 0 32px;
    }
  }
}

@media (min-width: $screen-size-very-big-1440) {
  .pp-terms-and-conditions {
    &__main-section {
      padding: 100px calc(152px + ((100vw - 1440px) / 2)) 0;

      &-title {
        padding: 0 0 40px 0;
        font-size: $font-size-40;
        line-height: $font-size-48;
      }

      &-subtitle {
        font-size: $font-size-32;
        line-height: $font-size-40;
      }

      &-paragraph {
        &:nth-child(5) {
          padding: 0 0 64px 0;
        }
      }

      &-second-block {
        &:last-of-type {
          padding: 0 0 150px 0;
        }
      }
    }

    &__footer-section {
      padding: 0 calc((100vw - 1440px) / 2);
    }
  }
}
