@import './../../Utils/Stylesheets/Constants.scss';

.pp-testimonials-image-slider {
  margin: 0;
  padding: 0;

  &__carousel-container {
    padding: 0 0 112px 0;

    &--dots-container {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      padding: 0px;
      gap: 32px;
      width: 128px;
      height: 40px;
      bottom: 48px;
      left: calc(50% - 64px);

      .react-multi-carousel-dot {
        button {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin: 0;
          padding: 0;
          background: $color-green-eaf7c0;
          box-shadow: none;
          border: none;
        }

        &--active {
          button {
            background: $color-green-bfd732;
          }
        }
      }
    }
  }
}

@media (min-width: $screen-size-medium-big-768) {
  .pp-testimonials-image-slider {
    .carousel {
      .slider {
        .slide {
          width: 335px;
          min-width: 0;
          margin: 0 16px 0 0;

          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
}
