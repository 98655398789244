@import './../../Utils/Stylesheets/Constants.scss';
@import './../../Utils/Stylesheets/Extends.scss';

.cookie-question-container {
  padding: 40px 0 64px;

  &__subtitle {
    @extend .bold-font;

    padding: 0 0 16px 0;
    font-size: $font-size-20;
    line-height: 28px;
  }
}

@media (min-width: $screen-size-medium-big-768) {
  .cookie-question-container {
    padding: 60px 0 84px;
  }
}

@media (min-width: $screen-size-big-1024) {
  .cookie-question-container {
    padding: 84px 0 108px;
  }
}

@media (min-width: $screen-size-very-big-1440) {
  .cookie-question-container {
    padding: 80px 0 81px;

    &__subtitle {
      width: 728px;
      padding: 0 24px 12px 0;
    }
  }
}
