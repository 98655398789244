@import './../../Utils/Stylesheets/Constants.scss';
@import './../../Utils/Stylesheets/Extends.scss';

.pp-faqs-container {
  padding: 56px 0 40px;

  &__title {
    @extend .bold-font;

    padding-bottom: 52px;
    font-size: $font-size-32;
    line-height: 40px;
  }

  &__accordion-container {
    .pp-accordion {
      &:first-child {
        border-radius: 12px 12px 0 0;
        .pp-accordion__block {
          border-radius: 12px 12px 0 0;
        }
      }
      &:last-child {
        border-radius: 0 0 12px 12px;
        .pp-accordion__block {
          border-radius: 0 0 12px 12px;
        }
        .pp-accordion__block--open {
          border-radius: 0;
        }
        .pp-accordion__answer {
          border-radius: 0 0 12px 12px;
        }
      }
    }
  }
}

@media (min-width: $screen-size-small-375) {
  .pp-faqs-container {
    padding: 64px 0 48px;

    &__title {
      padding-bottom: 48px;
    }
  }
}

@media (min-width: $screen-size-medium-big-768) {
  .pp-faqs-container {
    display: flex;
    justify-content: space-between;
    padding: 0 0 60px 0;

    &__title {
      padding: 76px 16px 0 0;
      width: 224px;
    }

    &__accordion-container {
      width: 464px;
      padding-top: 60px;

      .pp-accordion {
        &__question {
          width: 372px;
          letter-spacing: -.1px;

          &--open {
            width: 368px;
            padding-right: 28px;
          }
        }
      }
    }
  }
}

@media (min-width: $screen-size-big-1024) {
  .pp-faqs-container {
    &__accordion-container {
      width: 720px;

      .pp-accordion {
        &__question {
          width: 628px;

          &--open {
            width: 624px;
          }
        }
      }
    }
  }
}

@media (min-width: $screen-size-very-big-1440) {
  .pp-faqs-container {
    padding: 0 0 64px 0;

    &__title {
      padding: 84px 44px 0 0;
      width: 504px;
    }

    &__accordion-container {
      width: 560px;

      .pp-accordion {
        &__question {
          width: 468px;

          &--open {
            width: 464px;
          }
        }
      }
    }
  }
}
