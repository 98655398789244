@import './../../Utils/Stylesheets/Constants.scss';
@import './../../Utils/Stylesheets/Extends.scss';

.pp-cookie-table {
  &__title {
    @extend .bold-font;

    color: $color-grey-7b8794;
    font-size: $font-size-24;
    line-height: 32px;

    &--padding-bottom {
      padding: 0 0 48px 0;
    }
  }

  &__table {
    display: flex;
    width: 334px;
  }

  &__columns {
    display: flex;
    flex-direction: column;
    width: 124px;
    margin: 0 1px 0 0;

    &-subtitles {
      @extend .regular-font;

      width: 108px;
      height: 20px;
      padding: 8px;
      margin: 0 0 1px 0;
      background-color: $color-green-bfd732;
      font-size: $font-size-12;
      line-height: 20px;
      text-align: left;

      &:last-child {
        margin: 0;
      }

      &--small-letter {
        font-size: $font-size-8;
      }

      &--pad-purp-table1 {
        padding: 38px 8px;
      }

      &--pad-purp-table2 {
        padding: 88px 8px;
      }

      &--background {
        background-color: $color-green-d0e869;
      }
    }
  }

  &__rows {
    display: flex;
    flex-direction: column;
    width: 210px;

    &-text {
      @extend .regular-font;

      width: 194px;
      padding: 8px;
      background-color: $color-grey-f1f3f6;
      color: $color-black-3e4c59;
      font-size: $font-size-12;
      line-height: 20px;
      text-align: left;

      &:not(:last-child) {
        margin: 0 0 1px 0;
      }
    }
  }
}

@media (min-width: $screen-size-medium-small-412) {
  .pp-cookie-table {
    &__table {
      width: 371px;
    }

    &__columns {
      width: 139px;

      &-subtitles {
        width: 123px;

        &--pad-purp-table1 {
          padding: 28px 8px;
        }

        &--pad-purp-table2 {
          padding: 78px 8px;
        }
      }
    }

    &__rows {
      width: 233px;

      &-text {
        width: 217px;
      }
    }
  }
}

@media (min-width: $screen-size-medium-414) {
  .pp-cookie-table {
    &__title {
      &--padding-bottom {
        padding: 0 0 52px 0;
      }
    }

    &__table {
      width: 373px;
    }

    &__columns {
      width: 140px;

      &-subtitles {
        width: 124px;
      }
    }

    &__rows {
      width: 234px;

      &-text {
        width: 218px;
      }
    }
  }
}

@media (min-width: $screen-size-medium-big-768) {
  .pp-cookie-table {
    &__title {
      &--padding-bottom {
        padding: 0 0 40px 0;
      }
    }

    &__table {
      width: 703px;
    }

    &__columns {
      width: 224px;

      &-subtitles {
        width: 208px;

        &--pad-purp-table1 {
          padding: 18px 8px;
        }

        &--pad-purp-table2 {
          padding: 38px 8px;
        }
      }
    }

    &__rows {
      width: 479px;

      &-text {
        width: 463px;
      }
    }
  }
}

@media (min-width: $screen-size-big-1024) {
  .pp-cookie-table {
    display: flex;
    justify-content: center;

    &__title {
      width: 234px;
      margin: 0 16px 0 0;
    }

    &__table {
      display: block;
      width: 730px;
    }

    &__columns {
      flex-direction: row;
      width: 730px;

      &-subtitles {
        margin: 0 1px 0 0;

        &:last-child {
          margin: 0;
        }

        &--pad-purp-table1 {
          padding: 8px;
        }

        &--padding-pur-answer-tab2 {
          padding: 0;
        }

        &--padding-bigger-break-tab1 {
          padding: 50px 0;
        }

        &--padding-bigger-break-tab2 {
          padding: 120px 0;
        }
      }

      &--hide-column {
        display: none;
      }
    }

    &__rows {
      flex-direction: row;
      width: 730px;

      &-text {
        width: 130px;

        &:not(:last-child) {
          margin: 0 1px 0 0;
        }

        &--padding {
          padding: 8px;
        }
      }
    }
  }
}

@media (min-width: $screen-size-very-big-1440) {
  .pp-cookie-table {
    &__title {
      margin: 0 117px 0 33px;
    }

    &__table {
      width: 750px;
    }

    &__columns {
      width: 749px;

      &-subtitles {
        width: 150px;

        &--padding-bigger-break-tab1 {
          padding: 50px 0;
        }

        &--padding-bigger-break-tab2 {
          padding: 110px 0;
        }
      }
    }

    &__rows {
      width: 750px;

      &-text {
        width: 133px;

        &-spacing {
          letter-spacing: -0.1px;
        }
      }
    }
  }
}
