@import './../../Utils/Stylesheets/Constants.scss';
@import './../../Utils/Stylesheets/Extends.scss';

#HowItWorks {
  background: $color-green-fafcee;
}

.pp-how-it-works {

  &__title {
    @extend .bold-font;

    font-size: $font-size-32;
    line-height: $font-size-40;
    text-align: center;
    padding: 60px 0 0 0;
    margin: 0;
    display: block;
  }

  &__subtitle {
    font-size: $font-size-16;
    line-height: $font-size-24;
    text-align: center;
    padding: 16px 0 28px 0;
    margin: 0;
    color: $color-black-3e4c59;

    &.last {
      padding-bottom: 60px;

    }
  }

  &__workflow {
    margin: 0;
    padding: 40px 0 64px 0;

    &--item {
      padding: 0 0 84px 0 !important;
      margin: 20px 0 0 0 !important;
      position: relative;
      flex-direction: column;
      align-items: center;
      text-align: center;

      &:after {
        content: '';
        position: absolute;
        background-image: url('./../../Assets/Images/arrow-down.svg');
        background-size: 64px 64px;
        background-repeat: no-repeat;
        width: 64px;
        height: 64px;
        bottom: 0px;
        right: calc(50% - 32px);
      }

      &:first-child {
        margin: 0 !important;
      }

      &:last-child {
        padding: 0 !important;

        &:after {
          display: none;
        }
      }
    }
  }
}

@media (min-width: $screen-size-small-375) {
  .pp-how-it-works {
    &__title {
      padding: 48px 0 0 0;
    }
  }
}

@media (min-width: $screen-size-medium-big-768) {
  .pp-how-it-works {
    padding: 0 0 60px 0;

    &__subtitle {
      line-height: $font-size-32;
    }

    &__workflow {
      padding: 60px 0 60px 0;

      &--item {
        padding: 0 0 64px 0 !important;
        margin: 0 !important;
      }
    }
  }
}

@media (min-width: $screen-size-big-1024) {
  .pp-how-it-works {
    padding: 0 20px 0 20px;

    &__title {
      padding: 68px 0 0 0;
      font-size: $font-size-32;
      line-height: $font-size-44;
    }

    &__subtitle {
      padding: 16px 0 32px 0;
      &.last {
        font-size: $font-size-24;
  
      }
    }


    &__workflow {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-evenly;
      align-items: center;
      margin: 0;
      padding: 40px 20px 0 20px;

      &--item {
        padding: 0 8px 0 8px !important;
        margin: 0 16px 0 0 !important;
        position: relative;
        height: 210px;
        box-sizing: border-box;

        &:after {
          content: '';
          position: absolute;
          background-image: url('./../../Assets/Images/arrow-right.svg');
          background-repeat: no-repeat;
          background-size: 57px 64px;
          width: 57px;
          height: 64px;
          top: calc(50% - 33px);
          right: calc(-50% + 27px);
        }

        &:nth-child(1) {
          margin: 0 16px 0 0 !important;
        }

        &:nth-child(2) {
          margin: 0 16px 0 0 !important;

          &:after {
            background-size: 56px 64px;
          }
        }

        &:nth-child(3) {
          margin: 0 16px 0 0 !important;
        }

        &:nth-child(4) {
          margin: 0 !important;
          padding: 0 8px 0 8px !important;
        }
      }
    }
  }
}

@media (min-width: $screen-size-very-big-1440) {
  .pp-how-it-works {
    padding: 0 152px 0 152px;
    
    &__workflow {
      padding: 48px 0;

      &--item {
        padding: 0 !important;
        margin: 0 30px 0 0 !important;
        width: 325px !important;
        height: 210px;

        &:after {
          background-size: 64px 64px;
          width: 64px;
          height: 64px;
          top: 56px;
          right: -56px;
        }

        &:nth-child(1) {
          margin: 0 30px 0 0 !important;

        }

        &:nth-child(2) {
          margin: 0 30px 0 0 !important;

          &:after {
            right: -50px;
            width: 64px;
            height: 64px;
            background-size: 64px 64px;
          }
        }

        &:nth-child(3) {
          margin: 0 30px 0 0 !important;

          &:after {
            right: -56px;
          }
        }

        &:nth-child(4) {
          margin: 0 !important;
        }
      }
    }
  }
}
