@import './../../Utils/Stylesheets/Constants.scss';
@import './../../Utils/Stylesheets/Extends.scss';

.pp-accordion {
  margin: 0 0 2px 0;

  &__block {
    @extend .bold-font;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 22px 20px;
    background: $color-white-ffffff;
    cursor: pointer;

    &--open {
      padding: 22px 20px 22px 0;
    }
  }

  &__green-shape {
    width: 4px;
    height: 28px;
    border-radius: 0px 4px 4px 0px;
    background: $color-green-bfd732;
    margin: 0 20px 0 0;

    &--disabled {
      display: none;
    }
  }

  &__question {
    width: 236px;
    padding: 0 28px 0 0;

    &--open {
      width: 232px;
      padding: 0 28px 0 0;
    }
  }

  &__arrow-icon {
    width: 24px;
    height: 24px;
  }

  &__answer {
    padding: 0 20px 32px 24px;
    background: $color-white-ffffff;
    color: $color-black-3e4c59;
    line-height: 24px;
  }
}

@media (min-width: $screen-size-small-375) {
  .pp-accordion {
    &__question {
      width: 243px;

      &--open {
        width: 239px;
      }
    }
  }
}

@media (min-width: $screen-size-medium-small-412) {
  .pp-accordion {
    &__question {
      width: 280px;

      &--open {
        width: 276px;
      }
    }
  }
}

@media (min-width: $screen-size-medium-414) {
  .pp-accordion {
    &__question {
      width: 282px;

      &--open {
        width: 278px;
      }
    }
  }
}

@media (min-width: $screen-size-medium-big-768) {
  .pp-accordion {
    &__question {
      width: 252px;

      &--open {
        width: 248px;
      }
    }
  }
}

@media (min-width: $screen-size-big-1024) {
  .pp-accordion {
    &__question {
      width: 392px;

      &--open {
        width: 388px;
      }
    }
  }
}

@media (min-width: $screen-size-very-big-1440) {
  .pp-accordion {
    &__question {
      width: 468px;

      &--open {
        width: 464px;
      }
    }
  }
}
