@import './../../Utils/Stylesheets/Constants.scss';
@import './../../Utils/Stylesheets/Extends.scss';

.pp-nav-bar-mobile-toggle-button {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: transparent;
  border: none;
  margin: 0 30px 0 0;
  padding: 8px 8px 8px 8px;
  width: 40px;
  height: 40px;
  z-index: $in-face-layer;

  &--open {
    position: fixed;
    right: 9px;
    top: 20px;
    padding: 0;
    margin: 0;
  }

  &__bar {
    width: 20px;
    height: 2px;
    background: $color-green-a9ca26;
    margin: 0;
    transition: all 0.5s linear;

    &:nth-child(2) {
      margin: 5px 0;
    }

    &--open {
      position: absolute;
      width: 24px;
    }

    &--open:nth-child(1) {
      -webkit-transform: rotate(-45deg) translate(0, 0);
      transform: rotate(-45deg) translate(0, 0);
      bottom: 19.5px;
      left: 9px;
    }

    &--open:nth-child(2) {
      opacity: 0;
    }

    &--open:nth-child(3) {
      -webkit-transform: rotate(45deg) translate(0, 0);
      transform: rotate(45deg) translate(0, 0);
      bottom: 19.5px;
      left: 9px;
    }
  }
}

@media (min-width: $screen-size-medium-big-768) {
  .pp-nav-bar-mobile-toggle-button {
    margin: 0 72px 0 0;
    padding: 0;
    width: 24px;
    height: 24px;

    &--open {
      margin: 0;
    }
  }
}

@media (min-width: $screen-size-big-1024) {
  .pp-nav-bar-mobile-toggle-button {
    margin: 0 56px 0 0;
    padding: 8px 8px 8px 8px;
    width: 40px;
    height: 40px;

    &--open {
      margin: 0;
    }
  }
}
