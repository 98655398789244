@import './../../Utils/Stylesheets/Constants.scss';

.pp-support-page {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  z-index: $base-layer;

  #link-faqs-styles {
    text-decoration: underline;
  }

  a {
    text-decoration: none;
  }

  .pp-contact__email {
    a {
      color: $color-green-a9ca26;
    }
  }

  &__section {
    margin: 0;
    padding: 0 16px;

    &--grey {
      background-image: linear-gradient(
          to bottom,
          $color-grey-f9fafb 0%,
          $color-grey-f9fafb 100%
        ),
        linear-gradient(
          to bottom,
          $color-grey-f9fafb 0%,
          $color-grey-f9fafb 100%
        );
      background-color: $color-grey-f9fafb;
      background-clip: content-box, padding-box;
    }

    &--green {
      background-image: linear-gradient(
          to bottom,
          $color-green-e4f3a5 0%,
          $color-green-e4f3a5 100%
        ),
        linear-gradient(
          to bottom,
          $color-green-e4f3a5 0%,
          $color-green-e4f3a5 100%
        );
      background-color: $color-green-e4f3a5;
      background-clip: content-box, padding-box;
    }

    &--black {
      background-color: $color-black-25272a;
      background-clip: content-box, padding-box;
      background-image: linear-gradient(
          to bottom,
          $color-black-25272a 0%,
          $color-black-25272a 100%
        ),
        linear-gradient(
          to bottom,
          $color-black-25272a 0%,
          $color-black-25272a 100%
        );
    }
  }
}

@media (min-width: $screen-size-small-375) {
  .pp-support-page {
    &__section {
      padding: 0 20px;
    }
  }
}

@media (min-width: $screen-size-medium-big-768) {
  .pp-support-page {
    &__section {
      padding: 0 32px;
    }
  }
}

@media (min-width: $screen-size-big-1024) {
  .pp-support-page {
    &__section {
      &--wrapper {
        display: block;
        position: relative;
        max-width: 1024px;
        padding: 0 calc((100vw - 1024px) / 2) !important;
      }

      &--on-big {
        padding: 0;
      }
    }
  }
}

@media (min-width: $screen-size-very-big-1440) {
  .pp-support-page {
    &__section {
      padding: 0 152px 0 180px;

      &--wrapper {
        max-width: 1440px;
        padding: 0 calc((100vw - 1440px) / 2) !important;
      }

      &--on-big {
        padding: 0;
      }
    }
  }
}
