@import './../../Utils/Stylesheets/Constants.scss';
@import './../../Utils/Stylesheets/Extends.scss';

.pp-contact {
  display: block;
  margin: 0;
  padding: 60px 0 60px 0;

  &__left {
    display: block;
    margin: 0;
    padding: 0;
  }

  &__right {
    display: block;
    margin: 0;
    padding: 0;
  }

  &__title {
    @extend .bold-font;

    font-size: $font-size-32;
    line-height: $font-size-40;
    display: block;
    margin: 0;
    padding: 0;
  }

  &__subtitle {
    @extend .bold-font;

    font-size: $font-size-24;
    line-height: $font-size-32;
    color: $color-green-a9ca26;
    display: block;
    margin: 0;
    padding: 44px 0 16px 0;
  }

  &__main-text {
    font-size: $font-size-16;
    line-height: $font-size-24;
    color: $color-black-3e4c59;
    display: block;
    margin: 0;
    padding: 0 0 12px 0;
  }

  &__email {
    font-size: $font-size-20;
    line-height: $font-size-28;
    color: $color-green-a9ca26;
    margin: 0;
    padding: 0;

    a:any-link,
    a:-webkit-any-link {
      color: $color-green-a9ca26;
    }
  }
}

@media (min-width: $screen-size-medium-big-768) {
  .pp-contact {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 60px 0 64px 0;

    &__left {
      display: block;
      width: 344px;
      height: 116px;
    }

    &__right {
      display: block;
      width: 344px;
      height: 88px;
      padding: 84px 0 0 0;
    }

    &__subtitle {
      padding: 44px 0 0 0;
    }

    &__main-text {
      display: block;
      padding: 0 0 12px 0;
    }

  }
}

@media (min-width: $screen-size-big-1024) {
  .pp-contact {
    justify-content: space-between;
    padding: 70px 145px 160px 44px;

    &__left {
      width: 210px;
      height: 148px;
      padding: 0;
    }

    &__right {
      width: 359px;
      height: 88px;
      padding: 72px 0 0 0;
    }

    &__subtitle {
      width: 210px;
    }

    &__main-text {
      width: 359px;
    }
  }
}

@media (min-width: $screen-size-very-big-1440) {
  .pp-contact {
    justify-content: flex-start;
    padding: 84px 0 160px 180px;

    &__left {
      width: 340px;
      height: 112px;
      padding: 0;
    }

    &__right {
      width: 359px;
      height: 88px;
      padding: 80px 0 0 208px;
    }

    &__subtitle {
      padding: 40px 0 0 0;
      width: 340px;
    }

    &__main-text {
      padding: 0 0 12px 0;
    }
  }
}
